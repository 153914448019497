import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from '../ProTip';
import Divider from '@mui/material/Divider';
import AuthService from '../services/auth'

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { Navigate } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        FUDI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App() {
  const clientId = '278565504627-c1pdsvvd1p76sfp9h86fe680iau7bpsd.apps.googleusercontent.com'
  const [redirect,setRedirect] = React.useState({trigger: false, route: '/'})
  const onSuccess = (res) => {
      if (AuthService.login(res.credential)) {
        setRedirect({trigger: true, route: '/listas'})
      }
  };

  return (
    <Container maxWidth="sm">
      {redirect.trigger && (
            <Navigate to={redirect.route} replace={true} />
        )}
      <Box sx={{ mt: 10 }}>
        <Typography variant="h4" component="h1" align='center' gutterBottom>
          Bienvenido a FUDI
        </Typography>
        <ProTip />
        <Divider variant="middle" />
        <Container align="center" sx={{ mt: 4, mb: 5}}>      
          <GoogleOAuthProvider clientId={clientId} >
            <GoogleLogin
                onSuccess={onSuccess}
                onError={() => {
                  console.log('Login Failed');
                }}
                size='large'
            />
           </GoogleOAuthProvider>
        </Container>
        
      </Box>
      <Box sx={{ mt: 32 }}>
        <Copyright />
      </Box>
      
    </Container>
  );
}
