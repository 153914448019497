import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from './pages/Login';
import Lists from './pages/Lists';
import Share from './pages/Share';
import Home from './pages/Home'
import View from './pages/View'
import Join from './pages/Join'
import theme from './theme';
import Navbar from './components/Navbar';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const getPath = () => {
  const location = useLocation();
  return location.pathname
}

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <BrowserRouter>
    <Navbar getPath={getPath}/>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/inicio" element={<Home />} />
          <Route path="/share" element={<Share />} />
          <Route path="/login" element={<Login />}/>
          <Route path="/listas" element={<Lists />}/>
          <Route path="/view" element={<View />}/>
          <Route path="/join" element={<Join />}/>
          <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
);
