import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import authHeader from '../services/auth-header';
import { Navigate, useSearchParams } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LabelIcon from '@mui/icons-material/Label';

const mediaUrl = process.env.REACT_APP_FUDI_MEDIA_URL

export default function View() {
    const [redirect,setRedirect] = React.useState({trigger: false, route: '/'})
    const [currentListName,setCurrentListName] = React.useState('')
    const [userLists,setUserLists] = React.useState([])
    const [loadingPreview, setLoadingPreview] = React.useState(true)
    const [searchParams] = useSearchParams();
    const [postPreview,setPostPreview] = React.useState({description:'',image: '', url: '', author: ''})

    const apiUrl = process.env.REACT_APP_FUDI_API_URL
    const fetchPost = (postObj) => {
        axios.get(`${apiUrl}/post/${postObj['post']}`,{ headers: authHeader()})
        .then((response) => {
            let apiPost = response.data.payload
            apiPost['id'] = postObj['post']
            console.log(apiPost)
            setPostPreview(apiPost)
            setLoadingPreview(false)
        })
        .catch(error =>{ 
            console.error(error)
            setRedirect({trigger: true, route: '/login'});
        })
    }
    
    const handleViewClick = (e) => {
      window.location.href = e;
    }


    React.useEffect(() => {
      const params = Object.fromEntries(new URLSearchParams(location.search));
      fetchPost(params)
  }, [])

    const StyledMenu = styled((props) => (
        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...props}
        />
      ))(({ theme }) => ({
        '& .MuiPaper-root': {
          borderRadius: 6,
          marginTop: theme.spacing(1),
          minWidth: 180,
          color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
          boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          '& .MuiMenu-list': {
            padding: '4px 0',
          },
          '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
              fontSize: 18,
              color: theme.palette.text.secondary,
              marginRight: theme.spacing(1.5),
            },
            '&:active': {
              backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity,
              ),
            },
          },
        },
      }));

      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleListSelect = (id) => {
         let i = 0;
            while (i < userLists.length) {
                if ( id === userLists[i].id) {
                    setCurrentListName(userLists[i].name);
                    localStorage.setItem("currentList", id);
                    break;
                }
                i++;
            }
         setAnchorEl(null);
      }

      const handleSave = () => {
        const currentList = localStorage.getItem("currentList")
        postPreview['list'] = currentList
        setLoadingPreview(true)
        axios.post(`${apiUrl}/save`,postPreview,{ headers: authHeader()})
        .then((response) => {
            const apiData= response.data.payload
            setRedirect({trigger: true, route: '/inicio'})
            console.log(apiData)
        })
        .catch(error =>{ 
            console.error(error)
            // setRedirect({trigger: true, route: '/login'});
        })
      }

    return (
    <Container maxWidth="sm">
        {redirect.trigger && (
            <Navigate to={redirect.route} replace={true} />
        )}
        <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingPreview}
              >
                <CircularProgress color="inherit" />
            </Backdrop>
            
            {!loadingPreview &&
            (<Card sx={{ maxWidth: 445 }}>
            <CardMedia
                component="img"
                alt="social preview"
                height="440"
                image={`${mediaUrl}/cover/${postPreview.id}`}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                {postPreview.author}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {postPreview.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button color="inherit" size="large" onClick={ () => handleViewClick(postPreview.url)}>Ver</Button>
            </CardActions>
            </Card>)}
        </Box>
    </Container>
    );
}
