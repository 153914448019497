import * as React from 'react';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

function LightBulbIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M14.6923 6.08088C14.6923 5.48393 14.2618 5 13.7308 5H7.96154C7.4305 5 7 5.48393 7 6.08088V16.5294L7.76923 15.9529M10.2692 7.47059H16.0385C16.5695 7.47059 17 7.95452 17 8.55147V19L13.1538 16.1176L9.30769 19V8.55147C9.30769 7.95452 9.73819 7.47059 10.2692 7.47059Z" />
    </SvgIcon>
  );
}

export default function ProTip() {
  return (
    <>
    <Typography  variant="h6" sx={{ mt: 6, mb: 12 }} color="text.primary" align='center'>
      <LightBulbIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
      Un lugar para compartir, organizar y darle seguimiento a tus enlaces de redes sociales.
    </Typography>
    <Typography sx={{ mt: 6 }} color="text.secondary" align='center'>
      Para poder empezar a organizar tus enlaces, inicia sesion.
    </Typography>
    </>
  );
}
