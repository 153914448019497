import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import AuthService from '../services/auth';

import { Navigate } from 'react-router-dom';

const drawerWidth = 240;
const navItems = ['Inicio', 'Listas'];
const showSearch = ['/','/inicio','/listas']

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Navbar(props) {
  const path = props.getPath()
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [userName, setUserName] = React.useState("Usuario");
  const [redirect,setRedirect] = React.useState({trigger: false, route: '/'})

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleNavClick = (route) => {
    setRedirect({trigger: true, route: `/${route.toLowerCase()}`})
  }

  const isSelected = (menuItem) => {
    return `/${menuItem.toLowerCase()}` === path
  }

  React.useEffect(() => {
    setUserName(AuthService.getCurrentUser())

    
  }, "")

  if (redirect.trigger) {
    if (redirect.route === path) {
      setRedirect({trigger: false, route: '/'})
    }
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 , textAlign: 'center'}}>
        {userName}
      </Typography>
      <Divider sx={{ mt: 2 }}/>
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton selected={isSelected(item)} onClick={() => handleNavClick(item)} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
          <Divider sx={{ mt:2,mb:2}}/>
          {AuthService.isLogged() ?
          (<ListItem key='logout' disablePadding>
          <ListItemButton sx={{ textAlign: 'center', mt: 2 }}>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </ListItem>):
          (<ListItem key='login' disablePadding>
          <ListItemButton sx={{ textAlign: 'center', mt: 2 }}>
            <ListItemText primary='Login' />
          </ListItemButton>
        </ListItem>)
          }
          
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ flexGrow: 1 }}>
      {redirect.trigger && (
            <Navigate to={redirect.route} replace={true} />
        )}
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            FUDI
          </Typography>
          
          {showSearch.includes(path) && (
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar..."
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          )}
          {showOptions && (
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open options"
            onClick={handleDrawerToggle}
            sx={{ ml: 2 }}
          >
            <MoreVertIcon />
          </IconButton>)}
        </Toolbar>
      </AppBar>
      <Offset />
      <Box component="nav">
        <SwipeableDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Box>
    </Box>
  );
}