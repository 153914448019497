import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import axios from 'axios';
import authHeader from '../services/auth-header';
import { Navigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ViewListIcon from '@mui/icons-material/ViewList';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';

const apiUrl = process.env.REACT_APP_FUDI_API_URL
const mediaUrl = process.env.REACT_APP_FUDI_MEDIA_URL
const publicUrl = process.env.REACT_APP_PUBLIC_URL

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
      minHeight: 100,
    },
  }));


export default function Home(props) {
  const { window } = props;
    const [redirect,setRedirect] = React.useState({trigger: false, route: '/'})
    const [postsPreview,setPostsPreview] = React.useState([])
    const [viewMode,setViewMode] = React.useState("grid")
    const [loading, setLoading] = React.useState(true)
    const [fullOptionDrawer, SetFullOptionDrawer] = React.useState(false)
    const [shareLink,setShareLink] = React.useState('')
    const [drawerContent,setDrawerContent] = React.useState('listoptions')
    const [listName, setListName] = React.useState('');

    const handleViewPost = (e) => {
      console.log(e)
      setRedirect({trigger: true, route: `/view?post=${e}`})
    }
    
    const fullGrid = (posts) => {
        let i = 0;
        return (<Grid container spacing={1}>
              {posts.map((item,index) => (
                <Grid key={item.id} item xs={4}>      
                  <Box sx={{ position: 'relative' }} onClick={ () => handleViewPost(item.id)}>
                    <CardMedia
                        component="img"
                        alt="social preview"
                        height="200"
                        image={`${mediaUrl}/cover/${item.id}`}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.9)',
                        color: 'white',
                        padding: '5px',
                        display: 'flex',
                        justifyContent: "space-between"
                        
                      }}
                      style={{background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.10))'}}
                    >
                    <Typography variant="h6" noWrap>{item.author.substring(0, 10)}</Typography>
                    <IconButton
                      size="small"
                      aria-label="display more actions"
                      edge="end"
                      color="inherit"
                    >
                      <MoreIcon   />
                    </IconButton>
                    </Box>
                    <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      bgcolor: 'rgba(0, 0, 0, 0.54)',
                      color: 'white',
                      padding: '5px',
                    }}
                    style={{background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 1))'}}
                  >
                    {/* <Typography variant="h6">{item.author.substring(0, 15)}</Typography> */}
                    <Typography variant="body2" sx={{ display: '-webkit-box', overflow: 'hidden',WebkitBoxOrient: 'vertical',WebkitLineClamp: 3}} >{item.description}</Typography>
                  </Box>
                </Box>
              </Grid>
                ))}
            </Grid>)
    
    }

    const handleNameChange = () => {
      setListName(event.target.value)
  }
    
    const fetchPosts = () => {
        const currentList = localStorage.getItem("currentList")
        axios.get(`${apiUrl}/posts/preview/${currentList}`,{ headers: authHeader()})
        .then((response) => {
            const allPosts = response.data.payload
            setPostsPreview(allPosts)
            setLoading(false)
        })
        .catch(error =>{ 
            setRedirect({trigger: true, route: '/login'});
        })
    }

    const fetchShareLink = () => {
      const currentList = localStorage.getItem("currentList")
      setLoading(true)
      axios.get(`${apiUrl}/sharelink/${currentList}`,{ headers: authHeader()})
      .then((response) => {
          const shareObj = `${publicUrl}/join?code=${response.data.payload}`
          setShareLink(shareObj)
          setLoading(false)
      })
      .catch(error =>{ 
          setRedirect({trigger: true, route: '/login'});
      })
    }

    const shareShareLink = () => {
      if (navigator.share) {
        navigator.share({
          title: listName,
          text: `Te comparto mi lista ${listName}`,
          url: shareLink,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      }
    }

    const deleteList = () => {
        setLoading(true)
        const currentList = localStorage.getItem("currentList")
        axios.post(`${apiUrl}/delete/list/${currentList}`,{},{ headers: authHeader()})
        .then((response) => {
          setRedirect({trigger: true, route: '/listas'});
        })
        .catch(error =>{ 
            setRedirect({trigger: true, route: '/login'});
        })
    }

    const renameList = () => {
      setLoading(true)
      const currentList = localStorage.getItem("currentList")
      axios.post(`${apiUrl}/rename/list/${currentList}`,{name: listName},{ headers: authHeader()})
      .then((response) => {
        fetchPosts()
        SetFullOptionDrawer(false)
      })
      .catch(error =>{ 
          setRedirect({trigger: true, route: '/login'});
      })
  }

    const SetDrawerContent = (drawer) => {
      switch (drawer) {
        case 'listoptions':
          return (<MenuList style={{ borderTopLeftRadius: "30px",borderTopRightRadius: "30px", backgroundColor: "#1e1e1e"}}>
          <MenuItem onClick={() => OpenRenameList()}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Editar Nombre</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => OpenDeleteConfirm()}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Eliminar Lista</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <PeopleAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Miembros</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem>
            <ListItemIcon>
              <ViewListIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Vista de Lista</ListItemText>
          </MenuItem>
        </MenuList>)
        case 'confirmdelete':
          return (<Card 
            style={{ 
              borderTopLeftRadius: "30px",
              borderTopRightRadius: "30px"
            }}
            sx={{ py: 3, pl: 2}}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              <DeleteIcon fontSize="small"/>  Eliminar Lista
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Realmente quieres eliminar esta lista?
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={deleteList} color="inherit">Eliminar</Button>
              <Button size="small" onClick={HandleFullOptionDrawer} color="inherit">Cancelar</Button>
            </CardActions>
          </Card>)
        case 'shareoptions':
          return (<Card 
            style={{ 
              borderTopLeftRadius: "30px",
              borderTopRightRadius: "30px"
            }}
            sx={{ py: 3, pl: 3, pr: 2}}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              <ShareIcon fontSize="small"/>  Compartir 
              </Typography>
              <TextField
                label="Enlace"
                value={shareLink}
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
                fullWidth
              />
            </CardContent>
            <CardActions>
              {/* <Button size="small">Copiar</Button> */}
              <Button size="small" onClick={() => shareShareLink()}>Compartir</Button>
            </CardActions>
          </Card>)
        case 'renamelist':
          return (<Card 
            style={{ 
              borderTopLeftRadius: "30px",
              borderTopRightRadius: "30px"
            }}
            sx={{ py: 3, px: 2}}>
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            Nombra tu Lista
            </Typography>
            <TextField fullWidth size="medium" id='listName' sx={{ textAlign: 'center'}} label="Nombre de la Lista" variant="filled" onChange={handleNameChange} defaultValue={postsPreview.list_name}/>
            </CardContent>
            <CardActions>
            <Button size="small" onClick={event => renameList(event)} color="inherit">Actualizar</Button>
            <Button size="small" onClick={HandleFullOptionDrawer} color="inherit">Cancelar</Button>
            </CardActions>
            </Card>)
        default:
          console.log(`Sorry, we are out of ${drawer}.`);
      }
    }

    const OpenListOptions = () => {
      setDrawerContent('listoptions')
      SetFullOptionDrawer(true)
    }

    const OpenRenameList = () => {
      setDrawerContent('renamelist')
      SetFullOptionDrawer(true)
    }

    const OpenShareOptions = () => {
      fetchShareLink()
      setDrawerContent('shareoptions')
      SetFullOptionDrawer(true)
    }

    const OpenDeleteConfirm = () => {
      setDrawerContent('confirmdelete')
      SetFullOptionDrawer(true)
    }

    const HandleFullOptionDrawer = () => {
      SetFullOptionDrawer((prevState) => !prevState);
    };

    React.useEffect(() => {
        fetchPosts()
    }, [])
    const container = window !== undefined ? () => window().document.body : undefined
    return (
    <Container maxWidth="sm">
        {redirect.trigger && (
            <Navigate to={redirect.route} replace={true} />
        )}
        <Box sx={{ mt: 0 }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            >
            <CircularProgress color="inherit" />
        </Backdrop>
        <AppBar sx={{ mb: 1 }} position="static">
          <StyledToolbar>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ flexGrow: 1, alignSelf: 'flex-end' }}
            >
              {postsPreview.list_name}
            </Typography>
            <IconButton
              size="large"
              aria-label="display more actions"
              edge="end"
              color="inherit"
              onClick={OpenShareOptions}
            >
              <ShareIcon   />
            </IconButton>
            <IconButton
              size="large"
              aria-label="display more actions"
              edge="end"
              color="inherit"
              onClick={OpenListOptions}
            >
              <MoreIcon />
            </IconButton>
          </StyledToolbar>
        </AppBar>

            {viewMode === "grid" && ! loading ?
            (fullGrid(postsPreview.posts_previews)):
            (
                <Box sx={{ mt: 6 }}>
                </Box>   
            )
            }
            <Drawer
              container={container}
              anchor="bottom"
              variant="temporary"
              open={fullOptionDrawer}
              onClose={HandleFullOptionDrawer}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
              sx={{
                display: { xs: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
              }}
            >
            {SetDrawerContent(drawerContent)}
            </Drawer>
        </Box>
    </Container>
    );
}
