import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';

import axios from 'axios';
import authHeader from '../services/auth-header';

import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';


export default function Lists(props) {
    const { window } = props;
    const apiUrl = process.env.REACT_APP_FUDI_API_URL
    const [lists, setLists] = React.useState([])
    const [redirect,setRedirect] = React.useState({trigger: false, route: '/'})
    const [addListDrawer,setAddListDrawer] = React.useState(false)
    const [newListName, setNewListName] = React.useState('');
    const [loading, setLoading] = React.useState(true)

    const fabStyle = {
        position: 'fixed',
        bottom: 32,
        right: 32,
      };

    const fetchLists = () => {
        axios.get(`${apiUrl}/lists`,{ headers: authHeader()})
        .then((response) => {
            const allLists = response.data.payload
            setLists(allLists)
            setLoading(false)
        })
        .catch(error =>{ 
            setRedirect({trigger: true, route: '/login'});
        })
    }

    const handleNameChange = () => {
        setNewListName(event.target.value)
    }

    const handleOpenList = e => {
        // console.log(e)
        localStorage.setItem("currentList", e);
        setRedirect({trigger: true, route: '/'});
    }

    const handleDrawerToggle = () => {
        setAddListDrawer((prevState) => !prevState);
      };

    const handleAddList = () => {
        console.log(`creating list ${newListName}`)
        axios.post(`${apiUrl}/newlist`,{"name": newListName},{ headers: authHeader()})
        .then((response) => {
            console.log(response)
            handleDrawerToggle()
            fetchLists()
        })
        .catch(error =>{ 
            console.error(error);
        })
    }

    React.useEffect(() => {
        fetchLists()
    }, [])

    const drawer = (
        <Card 
        style={{ 
          borderTopLeftRadius: "30px",
          borderTopRightRadius: "30px"
        }}
        sx={{ py: 3, px: 2}}>
        <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Nombra tu Lista
        </Typography>
        <TextField fullWidth size="medium" id='listName' sx={{ textAlign: 'center'}} label="Nombre de la Lista" variant="filled" onChange={handleNameChange}/>
        </CardContent>
        <CardActions>
        <Button size="small" onClick={event => handleAddList(event)} color="inherit">Crear</Button>
        <Button size="small" onClick={handleDrawerToggle} color="inherit">Cancelar</Button>
        </CardActions>
        </Card>
      );

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
    <Container maxWidth="sm">
        {redirect.trigger && (
            <Navigate to={redirect.route} replace={true} />
        )}
        <Box sx={{ ml: 0}}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            >
            <CircularProgress color="inherit" />
        </Backdrop>
        <List>
            {lists.map((item) => (
            <ListItem id={item.id} key={item.id} disablePadding>
                <ListItemAvatar id={item.id}>
                    <Avatar>
                      <FormatListBulletedIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemButton id={item.id} sx={{ textAlign: 'left' }} onClick={ () => handleOpenList(item.id)}>
                <ListItemText
                    id={item.id}
                    primary={item.name} 
                    secondary={`${item.size} Enlaces`}
                />
                </ListItemButton>             
            </ListItem>
            
            ))}
        </List>
        </Box>
        <Box sx={{ m: 1 }}>
        <Fab sx={fabStyle} variant="extended" size="small" aria-label="add" onClick={handleDrawerToggle}>
            <AddIcon sx={{ mr: 1 }} />
            Agregar
        </Fab>
        <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={addListDrawer}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
          }}
        >
          {drawer}
        </Drawer>
        </Box>
    </Container>
    );
    }
