import axios from "axios";

const apiUrl = process.env.REACT_APP_FUDI_API_URL

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

class AuthService {
  login(jwt) {
    return axios.post(apiUrl + "/anounce",
        {},
        { headers: {'Authorization': `Bearer ${jwt}`} }
        )
      .then(response => {
        if (response.data.payload.token) {
          localStorage.setItem("tk", response.data.payload.token);
          return true;
        }

        return response.data;
      });
  }

  isLogged() {
    return (localStorage.getItem("tk") !== null)
  }

  logout() {
    localStorage.removeItem("tk");
  }

  getCurrentUser() {
    if (this.isLogged()) {
      return parseJwt(localStorage.getItem("tk")).name;
    }
    return '';
    
  }
}

export default new AuthService();